import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //isAuthenticated: false,
    session: {id: null},
    navItems: [
      //тут сделать связку по именам а не ссылкам, возможно передать параметры
      { title: 'Домой', url: '/dashboard', name:"dashboard", authRequired: true, icon: 'home' },
      { title: 'Клиенты', url: '/holder', name:"person", authRequired: true, icon: 'account_circle'  },
      { title: 'Партнеры', url: '/holder', name:"partner", authRequired: true, icon: 'business'  },
      // { title: 'Каталог', url: '/catalog', name:"catalog", authRequired: true },
      { title: 'Акции', url: '/discount', name:"discount", authRequired: true, icon: 'local_offer' },
      { title: 'Сертификаты', url: '/paypin', name:"certificate", authRequired: true, icon: 'card_giftcard' },
      { title: 'Счета', url: '/account', name:"account", authRequired: true, icon: 'account_box' },
      // { title: 'Бонусы', url: '/bonus', name:"bonus", authRequired: true },
      { title: 'Выйти', name:"logout", action: 'logout', authRequired: true, icon: 'exit_to_app' },
      { title: 'Sign-In', url: '/sign-in', name:"sign-in", authRequired: false },
      //authkeylist
    ],
    protocol: window.location.protocol,
    messageText: '',
    //actionCallback: null,
    loading: 0,
    savedRoute: null,
    checkSessionUrl: '/api/my/all'
  },

  mutations: {
    setAuth(state, payload) {
      //console.log(payload);
      //state.isAuthenticated = payload.auth;
      state.session = payload;
      },

    resetRoute(state) {
      state.savedRoute = null;
    },

    setRoute(state, route) {
      if (!state.savedRoute){
        state.savedRoute = route;
      }
    },

    resetAuth(state) {
      state.session = {};
    },

    showMessage(state, payload) {
//      console.log(payload);
      state.messageText = payload.text;
//      state.actionCallback = payload.callback;
    },

    hideMessage(state, payload) {
      state.messageText = '';
//      state.actionCallback = null;
    },

    setLoading(state, payload) {
      state.loading = payload;
      if (!payload) {
        state.loading--;
      }
      else if (payload) {
        state.loading++;
      }
//      state.actionCallback = null;
    }
  },

  actions: {
    // retryAction({state}){
    //   if (state.actionCallback) state.actionCallback.call(null);
    // },
    setLoading({commit}, value){
      if (value){
        commit("setLoading", value);
      }
      else{
        setTimeout(() => {commit("setLoading", value)}, 500);
      }
    },

    hideMessage({commit}, payload){
      commit("hideMessage");
    },
    showMessage({commit}, payload){
      commit("showMessage", payload);
    },

    postLogin({dispatch, state, commit}){
      dispatch("routerPushWrap", state.savedRoute || {name: 'Root'})
        .then(function(result){
          commit("resetRoute");
        })
        .then(null, function(error){
          console.log('store push postLogin');
          console.log(error);
        });
    },

    preAuth({commit, dispatch}){
      commit("resetAuth");
      commit("setRoute", router.currentRoute);
      dispatch("routerPushWrap", {name: 'Signin'})
        .then(null, function(error){
          console.log('store push preAuth');
          console.log(error);
        });

      /*if (router.currentRoute.path !== '/sign-in') {
        //router.push('/sign-in')
        router.push({name: 'Signin'})
          .then(null, function(error){
            console.log('store push preAuth');
            console.log(error);
          });
      }*/
    },

    routerPushWrap(context, route){
      if (typeof route === 'object'){
        if ( (!!route.name && router.currentRoute.name !== route.name)
          || (!!route.path && router.currentRoute.path !== route.path)
          || (!!route.params && !simpleComparision(router.currentRoute.params, route.params))
          || (!!route.query && !simpleComparision(router.currentRoute.query, route.query))
        ) {
          //console.log('go object: ');
          //console.log(route);
          router.push(route)
            .then(null, function(error){
              console.log('store pushWrap err, object:');
              console.log(route);
              console.log('was: ');
              console.log(router.currentRoute);
              console.log(error);
              return false;
            });
        }
      }
      //если рут - строка
      else if (typeof route === 'string'){
        if (router.currentRoute.path !== route){
          //console.log(route);
          router.push(route)
          //router.push(route.url)
            .then(null, function(error){
              console.log('store pushWrap: ' + route + ' was: ' + router.currentRoute.path);
              console.log(error);
              return false;
            });
        }
      }
      else{
        //console.log(route);
        context.dispatch("showMessage", {text: 'Неопознанный переход', error: true});
        return false;
      }

      return true;

      console.log('curr route');
      console.log(router.currentRoute);
      //console.log(route);
      //if (router.currentRoute.path !== url) {
      console.log(typeof route === 'object');


      if (router.currentRoute.name !== route.name && router.currentRoute.path !== route.path) {
          //{ name: 'user', params: { userId: 123 }}
          console.log(route);
          router.push(route)
          //router.push(route.url)
            .then(null, function(error){
              console.log('store pushWrap: ' + (route.name || route.path) + ' was: ' + router.currentRoute.path);
              console.log(error);
            });
        }
      //}
    },
    /*signClear({commit}){
      //console.log(router.currentRoute.path);
      commit("resetAuth");
      if (router.currentRoute.path !== '/sign-in') {
        router.push('/sign-in');//check if already
      }
    },*/

    signOut({ dispatch, commit }) {
      console.log('signOut');
      commit("setRoute", {name: 'Root'});
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/logout')
          .then(function(response){
            //console.log(response);
            dispatch("preAuth");
            resolve(response);
          })
          .then(null, function(error){
            //https://stackoverflow.com/questions/55926127/does-axios-have-the-ability-to-detect-redirects
            if (error.response.request.responseURL){
              dispatch("preAuth");
              resolve(error.response);
            }
            reject(error);
          });
      });
    },

    signOutRaw({ dispatch }) {
      console.log('signOutRaw');
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/logout')
          .then(function(response){
            //console.log(response);
            resolve(response);
          })
          .then(null, function(error){
            //https://stackoverflow.com/questions/55926127/does-axios-have-the-ability-to-detect-redirects
            if (error.response.request.responseURL){
              resolve(error.response);
            }
            reject(error);
          });
      });
    },

    async signIn({ dispatch }, user) {//1st - context object
      try {
        //console.log(user);
        //SPINNER
        //let response = await axios.get("${state.apiurl}", {'/api/login'
        let response = await axios.post('/api/login',
          { username: user.username, password: user.password });
        console.log('sign ok');
        //console.log(response);

        return response;
        //потому что апи сама кидает в корень, и еще есть interceptor, но в целом рабочий код
        /*return dispatch("checkAuth", 'store signIn')
          .then(function(response){
            console.log('signIn checkAuth ok '+response);
            dispatch("postLogin");
            return response;
          })
          .then(null, function(error){
            console.log("signIn checkAuth err, preAuth ");
            console.log(error);
            dispatch("preAuth");
            throw new Error(error.message);
          });*/
      }
      catch (error) {
        console.log('store signIn: '+ error.message);//ченить показать
        //console.log(error.response);
        //return error;
        throw new Error(error.message);
      }
    },

    checkAuth({ state, commit, dispatch }, debug) {//отладить двойные вызовы когда причешется
      return new Promise(function(resolve, reject){
        //SPINNEr on
        //console.log('storeAuthDebug: ' + debug);
        let request = axios.get("/api/my/all"
        //   , {
        //   params: {},//вот эти две сторочки нах
        //   headers: { "X-Requested-With": "XMLHttpRequest"}
        // }
        )
          .then((response) => {
            //console.log(response);
            if (response.data.holder.data.admin){
              commit("setAuth", response.data.holder);
              resolve(response);
            }
            else{
              dispatch("signOutRaw");
              setTimeout(function(){return reject('Unauthorized');}, 1000)
              //reject('Unauthorized');
              //resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
            //console.log(error);

            /*if (error.response && error.response.status === 401) {
              window.location.href = error.response.data;
            } */
            //console.log(error.response);
          })
          .finally(() => {
            //SPINNEr off
          });
        //commit("setRecipes", response.data.hits);
      });
      //commit("setRecipes", []);
    },

    getDashData(){
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/admin/dashdata/')
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            reject(error.message);
          });
      })
      .then(null, function(error){
        //console.log(error);
        //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
        throw new Error(error);
      });
    },

    getHolders(context, params){
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/admin/holders', {params: params})
          .then(function(response){
            //console.log(response);
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            /*if (error.response.request.responseURL){
              resolve(error.response);
            }*/
            reject(error.message);
          });
      })
        .then(null, function(error){
          //console.log(error);
          //context.dispatch("showMessage", {text: 'Ошибка получения holders: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolder(context, id){
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/admin/holder/' + id)
          .then(function(response){
            //console.log(response);
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            /*if (error.response.request.responseURL){
              resolve(error.response);
            }*/
            reject(error.message);
          });
      })
        .then(null, function(error){
          //console.log(error);
          //context.dispatch("showMessage", {text: 'Ошибка получения holders: ' + error, error: true});
          throw new Error(error);
        });
    },

    postHolder({dispatch}, holder){
      return new Promise(function(resolve,reject){
        const response = axios.post('/api/admin/holder', holder)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка добавления holder: ' + error, error: true});
        throw new Error(error);
      });
    },

    putHolder({dispatch}, holder){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/holder/' + holder.id, holder)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //console.log(error);
            //dispatch("showMessage", {text: 'Ошибка редактирования пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка редактирования holder: ' + error, error: true});
        throw new Error(error);
      });
    },

    getHolderCatalog(context, id){
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/admin/holder/'+id+'/catalog/')
          .then(function(response){
            //console.log(response);
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            /*if (error.response.request.responseURL){
              resolve(error.response);
            }*/
            reject(error.message);
          });
      })
      .then(null, function(error){
        //console.log(error);
        throw new Error(error);
      });
    },

    postHolderCatalog({dispatch}, holder){
      return new Promise(function(resolve,reject){
        const response = axios.post('/api/admin/holder/' + holder.get("id") + '/catalog', holder)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка добавления каталога: ' + error, error: true});
        throw new Error(error);
      });
    },

    putHolderCatalog({dispatch}, holder){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/holder/' + holder.get("id") + '/catalog', holder)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка редактирования каталога: ' + error, error: true});
        throw new Error(error);
      });
    },

    getHolderDiscounts({dispatch}, discount){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/holder/' + discount.holderId + '/discounts', {params: discount})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка получения акций: ' + error, error: true});
        throw new Error(error);
      });
    },

    getHolderDiscount({dispatch}, discount){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/discount/' + discount)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        //dispatch("showMessage", {text: 'Ошибка получения акции: ' + error.message, error: true});
        throw new Error(error);
      });
    },

    putHolderDiscount({dispatch}, discount){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/discount/' + discount.id, discount)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
      .then(null, function(error){
        //console.log(error);
        dispatch("showMessage", {text: 'Ошибка обновления акции: ' + error, error: true});
        throw new Error(error);
      });
    },

    postHolderDiscount({dispatch}, discount){
      return new Promise(function(resolve,reject){
        const response = axios.post('/api/admin/discount/', discount)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка добавления акции: ' + error, error: true});
          throw new Error(error);
        });
    },

    getDiscounts({dispatch}, discount){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/discounts', {params: discount})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения акций: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolderPaypins({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/holder/' + payload.holderId + '/paypins', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения paypins: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolderPaypin({dispatch}, paypin){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/paypin/' + paypin)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          //dispatch("showMessage", {text: 'Ошибка получения акции: ' + error.message, error: true});
          throw new Error(error);
        });
    },

    putHolderPaypin({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/paypin/' + payload.id, payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка обновления paypin: ' + error, error: true});
          throw new Error(error);
        });
    },

    getPaypins({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/paypins', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения paypins: ' + error, error: true});
          throw new Error(error);
        });
    },

    getAccounts({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/accounts', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения accounts: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolderAccounts({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/holder/' + payload.holderId + '/accounts', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения accounts: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolderAccount({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/account/' + payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения account: ' + error, error: true});
          throw new Error(error);
        });
    },

    putHolderAccount({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/account/' + payload.id, payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка обновления account: ' + error, error: true});
          throw new Error(error);
        });
    },

    getBanks({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/banks', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения banks: ' + error, error: true});
          throw new Error(error);
        });
    },

    getAccountBanks({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/account/' + payload.accountId + '/banks', {params: payload})
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения banks: ' + error, error: true});
          throw new Error(error);
        });
    },

    getBank({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.get('/api/admin/bank/' + payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка получения bank: ' + error, error: true});
          throw new Error(error);
        });
    },

    putBank({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/bank/' + payload.id, payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка обновления bank: ' + error, error: true});
          throw new Error(error);
        });
    },

    postBank({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.post('/api/admin/bank', payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка добавления bank: ' + error, error: true});
          throw new Error(error);
        });
    },

    getHolderAuthKey(context, holderId){
      return new Promise(function(resolve,reject){
        let response = axios.get('/api/admin/holder/'+holderId+'/authkey/')
          .then(function(response){
            //console.log(response);
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            /*if (error.response.request.responseURL){
              resolve(error.response);
            }*/
            reject(error.message);
          });
      })
      .then(null, function(error){
        //console.log(error);
        throw new Error(error);
      });
    },

    postHolderAuthKey({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.post('/api/admin/holder/'+payload.holderId+'/authkey/', payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка добавления authkey: ' + error, error: true});
          throw new Error(error);
        });
    },

    putAuthKey({dispatch}, payload){
      return new Promise(function(resolve,reject){
        const response = axios.put('/api/admin/authkey/' + payload.authKeyId, payload)
          .then(function(response){
            if (response.data.error){
              reject(response.data.error);
            }
            else{
              resolve(response);
            }
          })
          .then(null, function(error){
            //dispatch("showMessage", {text: 'Ошибка добавления пользователя', error: true});
            reject(error.message);
          })
      })
        .then(null, function(error){
          //console.log(error);
          dispatch("showMessage", {text: 'Ошибка обновления authkey: ' + error, error: true});
          throw new Error(error);
        });
    },
  },

  modules: {},
  getters: {
    isAuthenticated(state) {
      //console.log(state.session);
      if (state.session && state.session.id)
        return true;
      else
        return false;
    },
    navItems(state){
      return state.navItems;
    },
    messageText(state){
      return state.messageText;
    },
    getWindowProtocol(state){
      //console.log(router.currentRoute);
      return state.protocol;
    }
  }
});

function simpleComparision(oldVal, newVal) {
  if(!oldVal || !newVal || Object.keys(oldVal).length !== Object.keys(newVal).length) {
    return false;
  }

  for (let key of Object.keys(oldVal)) {
    if (oldVal[key] !== newVal[key]) {
      return false;
    }
  }

  return true;

  return newVal.filter(function(a) {
    //console.log(a);
    return oldVal.map(function(b) { return b.id; }).indexOf(a.id) === -1;
  }).length === 0 ? true: false;
}


/*actions: {
  actionA ({ commit }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commit('someMutation')
        resolve()
      }, 1000)
    })
  }
}
Now you can do:

  store.dispatch('actionA').then(() => {
    // ...
  })
And also in another action:

  actions: {
    // ...
    actionB ({ dispatch, commit }) {
      return dispatch('actionA').then(() => {
        commit('someOtherMutation')
      })
    }
  }*/

/*
function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}
*/