<template>
    <!--<v-container fluid>-->
    <div class="pt-4">
        <!--<v-progress-linear-->
                <!--:active="!dashData.length"-->
                <!--:indeterminate="true"-->
                <!--absolute-->
                <!--top-->
                <!--color="indigo accent-1"-->
                <!--height="3"-->
        <!--&gt;</v-progress-linear>-->
        <v-scale-transition>

        <v-data-iterator
                v-show="show"
                :items="dashData"
                item-key="name"
                hide-default-footer
                loading-text=""
                no-data-text=""
        >
            <!--<template v-slot:default="{ items, isExpanded, expand }">-->
            <template v-slot:default="props">
                <v-row>
                    <v-col v-for="item in props.items"
                            :key="item.name"
                            cols="12"
                            sm="12"
                            md="6"
                            lg="4">
                        <v-card class="mb-4" @click="itemShow(item) && itemClick(item)" color="rgb(0, 0, 0, 0.5)">
                            <!--<v-toolbar dense elevation="24" color="blue darken-4">-->
                                <!--<v-card-title>-->
                                    <!--<h4>{{ item.name }}</h4>-->
                                <!--</v-card-title>-->
                            <!--</v-toolbar>-->
                            <v-sheet
                                class="v-sheet--offset mx-auto mt-2"
                                :color="itemColor()"
                                elevation="12"
                                max-width="calc(100% - 32px)">
                                <v-sparkline
                                        :labels="item.graph.labels.length > 1 ? item.graph.labels : labels"
                                        :value="item.graph.values.length > 1 ? item.graph.values: values"
                                        color="white"
                                        line-width="2"
                                        padding="16"
                                        auto-draw
                                        :auto-draw-duration="500"
                                >
                                </v-sparkline>
                            </v-sheet>
                            <v-divider></v-divider>
                            <v-list dense color="rgb(0, 0, 0, 0.0)">
                                <v-list-item>
                                    <v-list-item-content class="title">{{itemTitle(item) || item.name || "John Doe"}}:</v-list-item-content>
                                    <v-list-item-content class="justify-end">
                                        {{ item.count }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content class="font-weight-light mb-2">Активные:</v-list-item-content>
                                    <v-list-item-content class="justify-end">
                                        {{ item.activeCount }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!--<v-divider></v-divider>-->
                            <v-card-text class="pt-0">
                                <!--<div class="title font-weight-light mb-2">-->
                                    <!--User Registrations-->
                                <!--</div>-->
                                <div class="subheading font-weight-light grey--text">
                                    Last Campaign Performance
                                </div>
                                <v-divider class="my-2"></v-divider>
                                <v-icon class="mr-2" small>schedule</v-icon>
                                <!--<span class="caption grey&#45;&#45;text font-weight-light">последняя регистрация {{item.lastCreate}} минут назад</span>-->
                                <span class="caption grey--text font-weight-light">последняя регистрация {{item.lastCreate}} назад</span>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue-grey" @click.stop="itemClick(item)" v-show="itemShow(item)" :disabled="!itemShow(item)">Перейти</v-btn>
                                <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        </v-scale-transition>
        <v-snackbar v-model="snackbar" timeout="-1" elevation="24">
            <div class="font-weight-light text-button">{{ snackbarText }}</div>
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text outlined v-bind="attrs" @click="snackbar = false">
                    Закрыть
                </v-btn>
                <v-btn color="green" text outlined v-bind="attrs" @click="snackbar = false; getDashData()" class="ml-2">
                    Обновить
                </v-btn>
            </template>
        </v-snackbar>
    </div>
    <!--</v-container>-->
</template>

<script>
  import colors from 'vuetify/lib/util/colors';
  //v-overlay loading можно заюзать
  //v-skeleton-loader для еще не данных

  export default {
    name: 'Dashboard',
    computed: {
    },
    
    mounted(){
      this.sparkColors = Object.keys(colors).map((main) => (
        Object.keys(colors[main]).filter((sub) => (
          //main + ' ' + sub
          //console.log(main);
          //return
          !sub.includes('lighten') && !sub.includes('white')
        )).map((newSub) => (
          colors[main][newSub]
        ))
      )).flat();
      this.getDashData();
    },
    
    methods:{
      getDashData(){
        this.$store.dispatch("setLoading", true);
        this.$store.dispatch("getDashData")
          .then((response) => {
            this.dashData = response.data;
          })
          .then(null, (error) => {
            this.showSnackBar('Ошибка получения данных: ' + error.message);//error.name
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
            this.show = true;
          });
      },

      showSnackBar(text){
        this.snackbar = true;
        this.snackbarText = text;
      },

      itemColor(){
        return this.sparkColors[Math.floor(Math.random() * this.sparkColors.length)] || 'cyan';
      },

      itemTitle(item){
        const findTitle = this.$store.getters.navItems
          .filter((n) => (n.name == item.name))
          .reduce((a,b) => (b), {});
        if (findTitle.title){
          return findTitle.title;
        }
        else
          return false;
      },

      itemShow(item){
        return !!this.$store.getters.navItems
          .filter((n) => (n.name == item.name))
          .reduce((a,b) => (b), {}).name;
      },

      itemClick(item){
        const findUrl = this.$store.getters.navItems
          .filter((n) => (n.name == item.name))
          .reduce((a,b) => (b), {});
        if (findUrl.url){
          //console.log(findUrl);
          //{ name: 'user', params: { userId: 123 }}
          //this.$store.dispatch("routerPushWrap", (findUrl.url == '/holders' ? findUrl.url + '/' + findUrl.name : findUrl.url) );
          //this.$store.dispatch("routerPushWrap",{path: (findUrl.url == '/holder' ? findUrl.url + '?type=' + findUrl.name : findUrl.url)});
          let query = {};
          switch(findUrl.url){
            case '/holder': query = {type: findUrl.name};break;
            case '/paypin': query = {type: 'coupon'};break;
          }
          let routeObject = {path: findUrl.url, query: query};
          //console.log(routeObject);
          this.$store.dispatch("routerPushWrap", routeObject);
        }
        else{
          this.showSnackBar('Ошибка перехода в "' + item.title +'"');
        }
      }
    },
    //вынести лоадер в сторе to appNav
    data() {
      return {
        show: false,
        snackbar: false,
        snackbarText: '',
        singleExpand: false,
        sparkColors:[],
        dashData: [],
        //labels: [0,0],
        //values: [0,0],
      }
    }
  };
</script>

<style scoped>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }
    .trans-v-card {
        color: rgb(0, 0, 0, 0.6);
    }
    .trans-v-list{
        color: rgb(0, 0, 0, 0.0);
    }
</style>