<template>
    <div>
        <v-navigation-drawer
                app v-model="drawer"
                class="blue-grey darken-2"
                dark
                disable-resize-watcher>
            <v-list>
                <template v-for="(item, index) in navItems">

                    <div :key="index">
                        <!--<div v-if="(!isAuthenticated && !item.authRequired) || item.url == '/'">-->
                        <template v-if="(!isAuthenticated && !item.authRequired) && item.name!=='logout'">
                            <!--<v-list-item :to="(item.url ? item.url : '')" @click="navClick(item)">-->
                            <v-list-item @click="navClick(item)">
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider :key="`divider-${index}`"></v-divider>
                        </template>
                        <template v-else-if="(isAuthenticated && item.authRequired) && item.name!=='logout'">
                            <v-list-item @click="navClick(item)">
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider :key="`divider-${index}`"></v-divider>
                        </template>
                    </div>
                </template>
            </v-list>
            <template v-slot:append v-if="isAuthenticated">
                <div class="pa-2">
                  <v-btn bottom block @click="logout" outlined>
                      <v-icon left>
                          exit_to_app
                      </v-icon>
                      Выйти
                  </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app color="blue-grey darken-4" dark dense>
        <!--<v-app-bar app color="blue-grey darken-4" dark dense @click="$store.dispatch('showMessage', { text: 'test', error: true })">-->
            <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer">
                <template v-slot:default>
                    <v-icon>menu</v-icon>
                </template>
            </v-app-bar-nav-icon>
            <v-spacer class="hidden-md-and-up"></v-spacer>
            <router-link to="/">
                <v-btn icon class="hidden-md-and-up" to="/">
                        <v-img max-height="36" max-width="36" src="../../public/img/home.png"></v-img>
                </v-btn>
            </router-link>
            <!--<router-link to="/">-->
                <!--<v-toolbar-title class="hidden-md-and-up" to="/">{{appTitle}}</v-toolbar-title>-->
            <!--</router-link>-->
            <div v-if="isAuthenticated" class="hidden-sm-and-down">
                <v-btn icon class="hidden-sm-and-down" to="/">
                    <!--Домой-->
                    <!--<v-img max-height="36" max-width="36" src="../../public/img/Bn_36.png"></v-img>-->
                    <v-icon>home</v-icon>
                </v-btn>
                <v-btn text class="hidden-sm-and-down" to="/holder">Пользователи</v-btn>
                <v-btn text class="hidden-sm-and-down" to="/discount">Акции</v-btn>
                <v-btn text class="hidden-sm-and-down" :to="{name: 'Paypins', query: {type: 'coupon'}}">Сертификаты</v-btn>
                <v-btn text class="hidden-sm-and-down" :to="{name: 'Accounts'}">Счета</v-btn>
            </div>
            <v-spacer class="hidden-sm-and-down"></v-spacer>

            <div v-if="!isAuthenticated" class="hidden-sm-and-down">
                <v-btn color="blue-grey lighten-3" to="/sign-in">Войти</v-btn>
            </div>
            <div v-else class="hidden-sm-and-down">
                <v-btn outlined color="white" @click="logout" data-cy="logout">
                    <v-icon left>
                        exit_to_app
                    </v-icon>
                    Выйти
                </v-btn>
            </div>

        </v-app-bar>
    </div>
</template>

<script>
  export default {
    name: 'AppNavigation',
    data() {
      return {
        appTitle: 'Бонусариум',
        drawer: false
      };
    },
    //из кнопок тоже сделать цикл
    computed: {
      navItems(){
        return this.$store.getters.navItems;
      },
      isAuthenticated(){
        //console.log('appnav is auth');
        //console.log(this.$store.getters.isAuthenticated);
         return this.$store.getters.isAuthenticated;
         //return true;
      }
    },
    methods: {
      logout() {
        this.$store.dispatch("setLoading", true);

        this.$store.dispatch('signOut')
          .finally(() => this.$store.dispatch("setLoading", false));
      },

      navClick(item){
        //console.log('navClick' + item);
        if (item.action && item.action == 'logout'){
          this.logout();
        }
        else{
          //this.$router.push(item.url);
          let query = {};
          switch(item.url){
            case '/holder': query = {type: item.name};break;
            case '/paypin': query = {type: 'coupon'};break;
          }
          let routeObject = {path: item.url, query: query};
          //console.log(routeObject);
          this.$store.dispatch("routerPushWrap", routeObject);
          //this.$store.dispatch('routerPushWrap', {path: item.url});
        }
      }
    }
  };
</script>

<style scoped>
  a {
    color: white;
    text-decoration: none;
  }
</style>