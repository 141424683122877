import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import "@babel/polyfill";

Vue.config.productionTip = false;

/*axios.interceptors.request.use(function(config) {
  const token = store.getters.token;
  if(token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function(err) {
  return Promise.reject(err);
});*/

//сделать гребаную сессию или http-basic to request
axios.interceptors.response.use(function(config) {
  /*const token = store.getters.token;
  if(token) {
    config.headers.Authorization = `Bearer ${token}`;
  } */
  /*console.log(config.headers.Location);
  if (config.headers.Location) {
    delete config.headers.Location;
  } */

  //console.log('main.js ok' + store.getters.isAuthenticated);
/*
  Vue.nextTick(() => {
    if (!store.getters.isAuthenticated){
      console.log('main.js checkAuth');
      store.dispatch("checkAuth", 'main.js')
        .then(null, function(error){
          console.log('main.js promise err: ' + error);
          store.dispatch("preAuth");
        });
    }
  });
*/

  if (!store.getters.isAuthenticated && config.config.url !== store.state.checkSessionUrl){
    console.log('main.js checkAuth');
    store.dispatch("checkAuth", 'main.js')
      .then(null, function(error){
        console.log('main.js promise err: ' + error);
        store.dispatch("preAuth");
      });
  }
  return config;
}, function(err) {
  console.log('main.js err: ' + err.response);
  //console.log(err.response);
  //на 500 сделать какой-нибудь emit глобальный
  //if ([401, 500].includes(err.response.status)){
  if ([401].includes(err.response.status) && err.response.config.url !== store.state.checkSessionUrl){
    store.dispatch("preAuth");
  }
  return Promise.reject(err);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
