import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

/*
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
*/

const routes = [
  {
    path: "/",
    redirect: '/dashboard',
    name: "Root"
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: '/sign-in',
    name: 'Signin',
    component: () => import('../views/Signin.vue')
  },
  // {
  //   path: '/user/:id',
  //   components: { default: User, sidebar: Sidebar },
  //   props: { default: true, sidebar: false }
  // },
  {
    path: '/holder/:holderId',
    component: () => import('../views/Holders.vue'),
    props: (route) => ({mode: route.params.holderId}),
    children: [
      {
        path: '',
        name: "Common",
        component: () => import('../components/HolderForm.vue'),
        props: (route) => ({holder: getParamId(route, 'holderId').holderId}),
      },
      {
        path: 'catalog',
        name: 'Catalog',
        component: () => import('../components/HolderCatalogForm.vue'),
        props: (route) => ({holder: getParamId(route, 'holderId').holderId}),
      },
      {
        path: 'catalog/:catalogId',
        redirect: 'catalog',
      },
      {
        path: 'discounts',
        redirect: 'discount',
      },
      {
        path: 'discount',
        component: () => import('../views/ViewWrap.vue'),
        children: [
          {
            path: '',
            name: 'HolderDiscounts',
            component: () => import('../components/HolderDiscounts.vue'),
            props: (route) => ({holder: getParamId(route, 'holderId').holderId, paramPagination: true, paramFilter: true }),
          },
          {
            path: ':discountId',
            name: 'HolderDiscount',
            component: () => import('../components/DiscountForm.vue'),
            props: (route) => ({
                holder: getParamId(route, 'holderId').holderId,
                param: getParamId(route, 'discountId').discountId,
                //mode: route.params['mode']
              })
          },
          {
            path: ':discountId/copy',
            name: 'HolderDiscountCopy',
            /*redirect : (to) => {
              return {name: 'HolderDiscount', params: {...to.params, mode: 'copy'} };
            },*/
            component: () => import('../components/DiscountForm.vue'),
            props: (route) => (
              //return {...to.params, mode: 'copy'};
              {
                holder: getParamId(route, 'holderId').holderId,
                param: getParamId(route, 'discountId').discountId,
                mode: 'copy'
              }
            ),
          },
        ]
      },
      {
        path: 'paypins',
        redirect: 'paypin',
      },
      {
        path: 'paypin',
        name: 'HolderPaypins',
        component: () => import('../components/PaypinList.vue'),
        props: (route) => ({holder: getParamId(route, 'holderId').holderId, paramPagination: true, paramFilter: true }),
      },
      {
        path: 'paypin/:paypinId',
        name: 'HolderPaypin',
        component: () => import('../components/PaypinForm.vue'),
        props: (route) => ({ holder: getParamId(route, 'holderId').holderId, param: getParamId(route, 'paypinId').paypinId}),
      },
      {
        path: 'accounts',
        redirect: 'account',
      },
      {
        path: 'account',
        name: 'HolderAccounts',
        component: () => import('../components/AccountList.vue'),
        props: (route) => ({holder: getParamId(route, 'holderId').holderId, paramPagination: false, paramFilter: false, paramHeader: true, paramTitle: false }),
      },
      {
        path: 'account/:accountId',
        //тут сделать редирект в корень такой же роут и передать props holderId и сдедлать обр ссылку в табе на холдера
        redirect : (to) => ({name: 'AccountCommon'/*, query: to.query*/}),
        //props: (route) => ({holder: getParamId(route, 'holderId').holderId}),
        //как то посчитать по роуту и убрать кнопку в счете на самого себя

        //name: 'HolderAccount',
        //component: () => import('../components/PaypinForm.vue'),
        //props: (route) => ({ holder: getParamId(route, 'holderId').holderId, param: getParamId(route, 'accountId').accountId}),
      },
      {
        path: 'authkeys',
        redirect: 'authkey',
      },
      {
        path: 'authkey',
        name: 'Authkey',
        component: () => import('../components/AuthKeyForm.vue'),
        // component: () => import('../components/HolderCatalogForm.vue'),
        props: (route) => ({holder: getParamId(route, 'holderId').holderId}),
      },
      {
        path: 'authkey/:authKeyId',
        redirect: 'authkey',
      },
    ]
  },
  {
    path: '/holders',
    //redirect: '/holders',
    redirect : (to) => ({name: 'Holders', query: to.query})
  },
  {
    path: '/holders/:holderId',
    //redirect: '/holders',
    redirect : (to) => ({name: 'Common'/*, query: to.query*/})
  },
  {
    path: '/holder',
    name: 'Holders',
    component: () => import('../views/Holders.vue'),
    //props: (route) => ({holderType: route.params.pathMatch.replace(/[^A-Za-z]/g,'')})
  },
  {
    path: '/discount/:discountId',
    name: 'Discount',
    component: () => import('../views/Discounts.vue'),
    props: (route) => ({ param: getParamId(route, 'discountId').discountId }),
  },
  {
    path: '/discounts',
    redirect: '/discount',
  },
  {
    path: '/discount',
    name: 'Discounts',
    component: () => import('../views/Discounts.vue'),
    //props: (route) => ({param: route.params.pathMatch.replace(/[^A-Za-z]/g,'')})
  },
  {
    path: '/paypins',
    redirect: '/paypin',
  },
  {
    path: '/paypin',
    name: 'Paypins',
    component: () => import('../views/Paypins.vue'),
  },
  {
    path: '/paypin/:paypinId',
    name: 'Paypin',
    component: () => import('../views/Paypins.vue'),
    props: (route) => ({ param: getParamId(route, 'paypinId').paypinId}),
  },
  {
    path: '/accounts',
    redirect: '/account',
  },
  {
    path: '/account',
    name: 'Accounts',
    component: () => import('../views/Accounts.vue'),
  },
  {
    path: '/accounts/:accountId',
    //redirect: '/holders',
    redirect : (to) => ({name: 'AccountCommon'/*, query: to.query*/})
  },
  {
    path: '/account/:accountId',
    //name: 'Account',
    component: () => import('../views/Accounts.vue'),
    props: (route) => ({ param: getParamId(route, 'accountId').accountId}),
    children: [
      {
        path: '',
        name: "AccountCommon",
        component: () => import('../components/AccountForm.vue'),
        props: (route) => ({param: getParamId(route, 'accountId').accountId}),
      },
      {
        path: 'bank',
        component: () => import('../views/ViewWrap.vue'),
        children: [
          {
            path: '',
            name: 'AccountBanks',
            component: () => import('../components/BankList.vue'),
            props: (route) => ({account: getParamId(route, 'accountId').accountId,
                                paramPagination: true, paramFilter: true, paramHeader: true, paramTitle: false }),
          //:holder="item.holderId"
          },
//
          {
            path: ':bankId',
            name: 'AccountBank',
            component: () => import('../components/BankForm.vue'),
            props: (route) => ({
                accountId: getParamId(route, 'accountId').accountId,
                param: getParamId(route, 'bankId').bankId,
                //mode: route.params['mode']
              })
          },
          {
            path: ':bankId/copy',
            name: 'AccountBankCopy',
            /*redirect : (to) => {
              return {name: 'HolderDiscount', params: {...to.params, mode: 'copy'} };
            },*/
            component: () => import('../components/BankForm.vue'),
            props: (route) => (
              //return {...to.params, mode: 'copy'};
              {
                accountId: getParamId(route, 'accountId').accountId,
                param: getParamId(route, 'bankId').bankId,
                mode: 'copy'
              }
            ),
          },
//
        ]
      },
    ]
  },
  {
    path: '/banks',
    redirect: '/bank',
  },
  {//это закрыть ибо тяжко
    path: '/bank',
    name: 'Banks',
    component: () => import('../views/Banks.vue'),
  },
  {
    path: '/bank/:bankId',
    name: 'Bank',
    component: () => import('../views/Banks.vue'),
    props: (route) => ({ param: route.params.bankId}),
  }
  // {
  //   path: '*',
  //   redirect: '/'
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

function getParamId(route, paramName){
  return {
    [paramName]: route.params[paramName] == 'add' ? null :
      (!isNaN(parseInt(route.params[paramName],10)) ? parseInt(route.params[paramName],10) : null)
  }
}

//https://itnext.io/vue-router-99e334094362
//.redir after auth to prev path
/*router.beforeEach((to, from, next) => {
if (to.matched.some(record => record.meta.authRequired)) {
if (!store.state.user) {
next({
path: '/sign-in'
});
} else {
next();
}
} else {
next();
}
});*/