<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <dashboard></dashboard>
        <!--<v-spacer></v-spacer>-->
        <!--<partners></partners>-->
    </v-container>
</template>

<script>
  import Dashboard from '@/components/Dashboard';
  // import Partners from '@/components/Partners';

  export default {
    name: 'Home',
    components: {
      Dashboard,
      // Partners
    },
    mounted() {
      //this.getDashboardData();
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
