<template>
  <v-app>
    <app-navigation></app-navigation>

    <v-slide-x-transition>
      <v-main v-show="show">
        <v-progress-linear
                :active="loading > 0"
                :indeterminate="true"
                color="indigo accent-1"
                height="2"
                fixed
        ></v-progress-linear>

        <router-view></router-view>

        <v-snackbar v-model="snackbar"
                    timeout="10000"
                    elevation="12"
                    bottom app
                    transition="v-scale-transition">
          <div class="font-weight-light text-button">{{ messageText }}</div>
          <template v-slot:action=" {attrs} ">
            <v-btn color="pink" text outlined v-bind="attrs" @click="handleMsg('close')">
              Закрыть
            </v-btn>
            <!--<v-btn color="green" text outlined v-bind="attrs" @click="handleMsg('refresh')" class="ml-2">-->
              <!--Обновить-->
            <!--</v-btn>-->
          </template>
        </v-snackbar>

      </v-main>
    </v-slide-x-transition>
  </v-app>
</template>

<script>
  import AppNavigation from '@/components/AppNavigation';

  export default {
    name: 'App',
    components: {
      AppNavigation
    },
    data(){
      return{
        show: false
      }
    },

    mounted(){
      this.show = true;
    },

    computed:{
      loading(){
        return this.$store.state.loading;
      },
      /*: {
        get(){
          return this.$store.state.loading;
        },
        set(v){
          return v;
        }
      },*/
      snackbar:{
        get(){
          //console.log('g ' + !!this.messageText);
          return !!this.messageText;// !== '';
        },
        set(v){
          //console.log('s ' + v);
          this.$store.dispatch("showMessage", {text: '', error: true});
          return v;
        }
      },
      messageText(){
        //console.log(this.$store.getters.messageText);
        return this.$store.getters.messageText;
      }
    },
    methods:{
      handleMsg(action,attrs){
        this.$store.dispatch("hideMessage");
        //для рефреша нужно замутить сообщение которое поймает App и emit в дочернее, короче надо подумать над сообщениями
        //{sender: 'dashboard', action: 'refresh'}
        // if (action == 'refresh'){
        //   this.$store.dispatch("retryAction");
        // }
        //event bus sample https://stackoverflow.com/questions/42632711/how-to-call-function-on-child-component-on-parent-events
      },
    }
  };
</script>

<style>
/*  .trans-body {
    background: transparent
  }*/
  .transparent-body {
    background: transparent
  }
  .expanded-closing {
    border-bottom: none !important;
  }
  .myheader {
    background-color: deeppink !important;
    border-color: white !important;
  }
  .pagination{
    border-top: thin solid hsla(0,0%,100%,.12);
  }
  .wb {
    word-break: break-all;
  }
  .bonus-background {
    /*background: url('http://source.unsplash.com/0BhSKStVtdM');*/
    background: url('../public/img/background2.jpg');
    background-size: contain;
    width: 100%;
    height: 100%;
  }
</style>

<!--https://coderoad.ru/56364965/%D0%9A%D0%B0%D0%BA-%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%B8%D1%82%D1%8C-vuetify-2-0-beta-%D0%B2-%D0%BD%D0%BE%D0%B2%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82-vue-cli-->